import '/assets/styles/pages/category.scss';

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase'

// Services
import productServices from '/services/product';

// Functions
import isExact from 'pstv-commerce-tools/functions/is-exact';

// Sections
import ListingHead from '/views/sections/listing-head';
import Listing from '/views/sections/listing';
import Breadcrumbs from '/views/sections/breadcrumbs';

// Context
import { useNavigator } from 'pstv-commerce-tools/utilities/navigator'
import { useHead } from 'pstv-commerce-tools/utilities/head';
import { ListingProvider, decodeQueryString, useListing } from 'pstv-commerce-tools/utilities/listing';
import ListingFilters from '../sections/listing-filters';

const getBrandProducts = (brandId, query) => {
	return productServices.getBrandProducts(brandId, query)
}

const parseHead = (brand) => {
	const title = brand.seo_title || brand.name;
	return {
		title: title,
		description: brand.seo_description || `${title} ürünleri.`,
	}
}

const defaultQuery = {
	page: '1',
}

const BrandQueryProvider = ({match, ...props}) => {
	return (
		<ListingProvider
			externalParams={{ brandId: match?.params?.id }}
			queryString={match?.params?.query}
			defaultQuery={defaultQuery}>
			<Brand {...props} />
		</ListingProvider>
	)
}

const Brand = ({ pageProps }) => {
	const { query, outputData } = useListing();
	const { redirect } = useNavigator();

	const { setHead } = useHead();
	const [{products, pagination, fetchedQuery, brand}, setProducts] = useState(pageProps?.productData ?? {products: null, fetchedQuery: null, pagination: null, brand: null});

	useEffect(() => {
		if(brand) {
			setHead(parseHead(brand));
		}
	}, [brand])

	useEffect(() => {
		if(query.brandId && !isExact(fetchedQuery, query)) {
			const { brandId, ...queryParams } = query;
			setProducts({ products: null, pagination: null, fetchedQuery: query });
			getBrandProducts(brandId, queryParams).then(productData => { setProducts({ ...productData, fetchedQuery: query}) });
		}
	}, [query, fetchedQuery])

	useEffect(() => {
		if(brand && brand.id.toString() === outputData?.externalParams?.brandId) {
			const params = {
				id: outputData.externalParams.brandId,
				slug: brand.url ?? kebabCase(brand.name)
			};
	
			if(outputData.queryString && outputData.queryString.length) {
				params.query = outputData.queryString;
			}
			redirect('brand', params)
		}
	}, [outputData, brand])

	return (
		<>
			<Breadcrumbs data={brand ? [{ name: `${brand.name} Markalı Ürünler` }] : undefined} />
			<ListingHead title={brand && `${brand.name} Markalı Ürünler`} />
			<ListingFilters />
			<Listing
				products={products}
				pagination={pagination} />
		</>
	)
}

Brand.propTypes = {
	pageProps: PropTypes.object
}

BrandQueryProvider.getServerProps = ({ match }) => {
	return new Promise((resolve) => {
		const rawQuery = decodeQueryString({
			queryString: match.params.query,
			defaultQuery,
			externalParams: { brandId: match.params.id }
		});

		const { brandId, ...query } = rawQuery;
		Promise.all([
			getBrandProducts(brandId, query)
		]).then(([productData]) => {
			resolve({
				pageProps: {
					productData: {
						...productData,
						fetchedQuery: rawQuery,
					},
				},
				head: parseHead(productData.brand),
			})
		}).catch(() => {
			resolve({
				status: 500,
			})
		})
	})
}

BrandQueryProvider.propTypes = {
	match: PropTypes.object,
}

export default BrandQueryProvider
